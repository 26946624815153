import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';
import { JobOffersType } from 'types/home-page.d';

import Layout from 'components/templates/layout';
import IndustriesAbout, { IndustriesAboutType } from 'components/organisms/industries-about';
import IndustriesServices, {
  IndustriesServicesType,
} from 'components/organisms/industries-services';
import LetsTalkJobOffers from 'components/organisms/lets-talk-job-offers';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';
import IndustriesContactUsBanner, {
  IndustriesContactUsBannerType,
} from 'components/molecules/industries-contact-us-banner';
import { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';

import { AllJobOfferType } from '../types/job-offers';

type DataProps = {
  industriesDetailsPage: {
    template: {
      industriesDetailsData: {
        bannerTertiary: IndustriesBannerType;
        services: IndustriesServicesType;
        about: IndustriesAboutType;
        contactUsBanner: IndustriesContactUsBannerType;
        jobOffers: JobOffersType;
        team: ManagerSectionType;
        trustSection: TextBannerCardType;
      };
    };
  };
  allJobOfferType: AllJobOfferType;
  commonComponents: {
    commonComponentsData: { letsTalkBanner: LetsTalkBannerType };
  };
};

const IndustriesHubPage = ({
  pageContext,
  data: {
    industriesDetailsPage: {
      template: {
        industriesDetailsData: {
          services,
          about,
          contactUsBanner,
          jobOffers,
          team,
          trustSection,
          bannerTertiary,
        },
      },
    },
    allJobOfferType,
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <IndustriesBanner {...bannerTertiary} isWhiteBreadcrumb pageContext={pageContext} />
    <IndustriesAbout {...about} />
    <IndustriesServices bgColor="#ECEFF4" {...services} />
    <IndustriesContactUsBanner {...contactUsBanner} />
    <TextBannerCardSection {...trustSection}>
      {trustSection
        ? trustSection.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
    <ManagerSection {...team} />
    <LetsTalkJobOffers
      jobOffersSlider={{
        heading: jobOffers.heading,
        subHeading: jobOffers.subHeading,
        linkButton: jobOffers.linkButton,
        cards: allJobOfferType.edges,
      }}
      letsTalkBanner={letsTalkBanner}
    />
  </Layout>
);

export const query = graphql`
  query IndustriesDetailsPage($id: String, $commonComponentsSlug: String!, $unitType: String!) {
    industriesDetailsPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_IndustriesDetails {
          industriesDetailsData {
            services {
              heading
              subHeading
              text
              servicesList {
                heading
                text
                linkButton {
                  icon {
                    name
                  }
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
            about {
              text
              heading
              problemHeading
              problemsList {
                text
              }
            }
            contactUsBanner {
              text
              linkButton {
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    target
                    url
                  }
                }
              }
            }
            bannerTertiary {
              heading
              text
              icon {
                name
              }
              image {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            trustSection {
              heading
              text
              cardList: trustList {
                text
                icon {
                  name
                }
              }
            }
            jobOffers: jobOffersSection {
              subHeading: subTitle
              heading
              linkButton {
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    target
                    url
                  }
                }
              }
            }
            team {
              backgroundImage {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              businessCards {
                businessCard {
                  contactList {
                    type
                    link {
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                      ariaLabel
                    }
                  }
                  fullName
                  image {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  text
                  position
                  positionTitle
                }
              }
            }
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offersData: { unit: { eq: $unitType } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default IndustriesHubPage;
